<template>
    <div v-if="rpid">
        <v-card flat color="transparent">
            <v-card-title>
                <v-icon large left>mdi-server</v-icon>
                <div class="text-h6 secondary--text">Large file storage</div>
                <v-spacer></v-spacer>
                <v-btn @click="openStorageDialog" :disabled="availableStorageTierList.length === 0" color="primary" v-if="canModify">
                    <v-icon left>add</v-icon>
                    <span class="font-weight-bold">add</span>
                </v-btn>
            </v-card-title>
            <v-container fluid v-if="activeStorageList.length > 0">
                <h4 class="mb-2">Active</h4>
                <storage-card-list
                    :items="activeStorageList"
                    :can-modify="availableStorageTierList.length > 0 && canModify"
                    @storageSelected="selectStorage"
                    @showMountDialog="fetchMounts"
                    :selectedRP="selectedRP" />
            </v-container>
            <v-container fluid v-if="cancelledStorageList.length > 0">
                <h4 class="mb-2">Cancelled</h4>
                <storage-card-list
                    :items="cancelledStorageList"
                    :can-modify="availableStorageTierList.length > 0 && canModify"
                    @showMountDialog="fetchMounts" />
            </v-container>
            <v-container v-if="purchasedStorages.length === 0">
                <dashboard-loader v-if="fetchingPurchasedStorages" />
                <dashboard-error v-if="!fetchingPurchasedStorages && hasError" />
                <dashboard-empty v-else-if="purchasedStorages.length === 0">No additional storage have been created yet.</dashboard-empty>
            </v-container>
            <v-dialog persistent v-model="addStorageDialog" :width="dialogMaxWidth" v-if="availableStorageTierList.length > 0">
                <v-card>
                    <v-card-title>
                        {{ selectedStorage ? 'Edit' : 'Add new' }} storage
                        <v-spacer />
                        <v-btn icon href="https://docs.nuvolos.cloud/features/file-system-and-storage/large-file-storage" target="_blank">
                            <v-icon color="primary">help</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-divider class="mb-6" />
                    <v-card-text>
                        <v-form v-model="addStorageForm" ref="addStorageForm">
                            <v-text-field
                                outlined
                                v-model="storageName"
                                :rules="[rules.required, rules.unique]"
                                label="Type a name for your storage"
                                hint="Please select a unique name, using letters and numbers"
                                :autofocus="!selectedStorage"
                                class="mb-5"></v-text-field>
                            <v-text-field
                                label="Please select size"
                                outlined
                                v-model="storageSize"
                                :min="minSize"
                                :max="selectedTierObject?.max_size_gib"
                                step="10"
                                persistent-hint
                                type="number"
                                :rules="[rules.integer, rules.required, rules.gte(minSize), rules.lte(selectedTierObject?.max_size_gib), rules.increment]"
                                :hint="`AC ${availableStorageTierList[0].unit_price.toFixed(2)} / GiB / year`"
                                class="mb-5">
                                <template v-slot:append-outer>GiB</template>
                            </v-text-field>
                            <v-row>
                                <v-col>
                                    <v-alert text type="info">
                                        This storage can be attached in {{ mappedSpaces }} space{{ mappedSpaces > 1 ? 's' : '' }}.
                                    </v-alert>
                                </v-col>
                            </v-row>
                            <v-divider class="my-3" />
                            <v-row align="end">
                                <v-col>
                                    <div class="text-body-1">Total price</div>
                                </v-col>
                                <v-col cols="8">
                                    <h2 class="text-right text-h4">
                                        AC {{ totalPrice }}
                                        <span class="text-body-1">/ year</span>
                                    </h2>
                                </v-col>
                            </v-row>
                            <v-divider class="mt-3" />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="error" text v-if="selectedStorage" @click="showCancelDialog">Cancel storage</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeDialog">Back</v-btn>
                        <v-btn color="primary" @click="showConfirmEditDialog" :disabled="!addStorageForm" v-if="selectedStorage" :loading="editingStorage">
                            Modify
                        </v-btn>
                        <v-btn color="primary" @click="showConfirmAddDialog" :disabled="!addStorageForm" :loading="addingStorage" v-else>Add</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="mountDataDialog" :width="dialogMaxWidth" v-if="mountData">
                <v-card>
                    <v-card-title>Usage info</v-card-title>
                    <v-card-text>
                        <dashboard-error v-if="spaceDataError" />
                        <div v-else-if="mountData.length > 0">
                            <p>This storage is attached in the following spaces:</p>
                            <v-divider />
                        </div>
                        <dashboard-empty v-else>This storage is not attached to any spaces yet.</dashboard-empty>
                        <v-expansion-panels accordion flat v-if="mountData.length > 0">
                            <v-expansion-panel v-for="space in mountData" :key="space.sid" :readonly="space.space_admins.length === 0">
                                <v-expansion-panel-header>
                                    {{ space.long_id }}
                                    <template v-slot:actions v-if="space.space_admins.length === 0">
                                        <v-chip small disabled>Space has no admins</v-chip>
                                    </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-sheet rounded color="grey lighten-4">
                                        <v-list subheader two-line color="transparent">
                                            <v-subheader>Space admins</v-subheader>
                                            <v-list-item v-for="admin in space.space_admins" :key="admin.email">
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ admin.name }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ admin.email }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action>
                                                    <copy-to-clipboard
                                                        :textToCopy="admin.email"
                                                        buttonClass="caption font-weight-bold"
                                                        buttonColor="secondary"
                                                        tooltip="Copy email to clipboard"
                                                        :iconButton="true"
                                                        :isSmall="true"></copy-to-clipboard>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </v-list>
                                    </v-sheet>
                                </v-expansion-panel-content>
                                <v-divider />
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer />
                        <v-btn text @click="mountDataDialog = false">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card>
    </div>
</template>

<script>
import eventBus from '@/components/EventBus'
import { DateTime } from 'luxon'

import StorageCardList from './StorageCardList.vue'

const CopyToClipboard = () => '@/components/CopyToClipboard'
const DashboardEmpty = () => import('@/components/DashboardEmpty.vue')
const DashboardLoader = () => import('@/components/DashboardLoader')
const DashboardError = () => import('@/components/DashboardError')

export default {
    props: {
        rpid: Number,
        expired: {
            type: Boolean,
            default: false
        },
        selectedRP: Object
    },
    components: { StorageCardList, CopyToClipboard, DashboardEmpty, DashboardLoader, DashboardError },
    data() {
        return {
            fetchingPurchasedStorages: false,
            fetchingAvailableStorages: false,
            storageTiers: {
                'afs-premium': { name: 'Premium', description: 'SSD storage optimized for IO intensive workloads that require low latency.' },
                'afs-to': { name: 'Transaction optimized', description: 'HDD storage for IO intensive workloads, where latency is not a priority.' },
                'afs-hot': { name: 'Hot', description: 'Hot storage is non-intensive workloads, where latency is low priority.' },
                'afs-cool': { name: 'Cool', description: 'Cool storage is for archival purposes, where the stored data is rarely accessed' }
            },
            availableStorageTiers: [],
            purchasedStorages: [],
            rules: {
                required: value => !!value || 'This field is required',
                unique: value => !this.storageNames.includes(value) || 'This name is in already use',
                integer: value => parseInt(value) === Number(value) || 'Whole numbers only',
                lte: max => value => max >= value || `Should be ${max} at most.`,
                gte: min => value => min <= value || `Should be at least ${min}.`,
                increment: value => value % this.storageSizeStep === 0 || `Please use increments of ${this.storageSizeStep}.`
            },
            addStorageDialog: false,
            addStorageForm: true,
            addingStorage: false,
            editingStorage: false,
            selectedTier: null,
            storageSize: 0,
            storageSizeStep: 10,
            storageName: '',
            dialogMaxWidth: '480px',
            selectedStorage: null,
            mappedSpaces: 0,
            fetchingMountData: {},
            mountData: null,
            mountDataDialog: false,
            confirmAction: null,
            hasError: false,
            spaceDataError: false
        }
    },
    computed: {
        selectedTierObject() {
            if (!this.selectedTier) return null
            return this.availableStorageTiers.find(tier => tier.subresource === this.selectedTier)
        },
        totalPrice() {
            if (!this.selectedTierObject || !this.storageSize) return 0
            const p = this.storageSize * this.selectedTierObject.unit_price
            if (p < 0) return 0
            return p.toFixed(2)
        },
        storageNames() {
            const storageList = this.purchasedStorages.map(storage => storage.long_id)
            if (this.selectedStorage) return storageList.filter(storage => storage !== this.selectedStorage.long_id)
            return storageList
        },
        minSize() {
            if (this.selectedStorage) return this.selectedStorage.quota_gib
            return this.selectedTierObject?.min_size_gib || 0
        },
        availableStorageTierList() {
            return this.availableStorageTiers?.map(tier => ({
                ...tier,
                name: this.storageTiers[tier.subresource].name,
                description: this.storageTiers[tier.subresource].description
            }))
        },
        activeStorageList() {
            return this.purchasedStorages.filter(storage => !storage.cancellation_timestamp)
        },
        cancelledStorageList() {
            return this.purchasedStorages.filter(storage => storage.cancellation_timestamp)
        },
        today() {
            return DateTime.now().toISODate()
        },
        isResourcePoolManager() {
            return this.selectedRP?.is_rp_manager
        },
        canModify() {
            return this.isResourcePoolManager && !this.expired && !this.selectedRP?.deleted
        }
    },
    methods: {
        async fetchPurchasedStorages() {
            const url = `resource/${this.rpid}/azure_file_shares`
            this.fetchingPurchasedStorages = true
            try {
                const { data } = await this.$axios.get(url)
                this.purchasedStorages = data.sort((a, b) => {
                    return a.long_id.localeCompare(b.long_id)
                })
            } catch {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Could not fetch list of purchased storage.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
                this.hasError = true
            }
            this.fetchingPurchasedStorages = false
        },
        async fetchAvailableStorages() {
            this.availableStorageTiers = []
            const url = `/resource/${this.rpid}/afs_products`
            this.fetchingAvailableStorages = true
            try {
                const { data } = await this.$axios.get(url)
                this.availableStorageTiers = data
                if (this.availableStorageTiers.length === 1) this.selectedTier = data[0].subresource
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Could not fetch storage tiers.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.fetchingAvailableStorages = false
        },
        openStorageDialog() {
            this.selectedTier = this.availableStorageTierList[0].subresource
            this.storageSize = this.minSize
            this.addStorageDialog = true
        },
        async addStorage() {
            const url = 'lfs/afs'
            const body = {
                quota_gib: this.storageSize,
                subresource: this.selectedTierObject.subresource,
                rpid: this.rpid,
                long_id: this.storageName
            }
            try {
                await this.$axios.post(url, body)
                this.fetchPurchasedStorages()
            } catch (error) {
                const code = error.response.data?.code
                if (code === 'failed_to_create_afs') {
                    this.insufficientFunds()
                } else {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Could not create storage.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                }
            }
            this.addStorageDialog = false
            this.storageName = ''
            this.$emit('refresh')
        },
        async editStorage() {
            const afsid = this.selectedStorage.afsid
            const url = `lfs/afs/${afsid}`
            const body = {}
            if (this.selectedStorage.long_id !== this.storageName) body.long_id = this.storageName
            if (this.selectedStorage.quota_gib !== this.storageSize) body.quota_gib = this.storageSize
            if (!Object.keys(body).length) {
                this.closeDialog()
                return
            }
            this.editingStorage = true
            try {
                await this.$axios.patch(url, body)
                this.fetchPurchasedStorages()
            } catch (error) {
                const code = error.response.data?.code
                if (code === 'failed_to_update_afs') {
                    this.insufficientFunds()
                } else {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Could not modify storage`,
                        snackBarTimeout: 5000,
                        snackBarIcon: 'error'
                    })
                }
            }
            this.editingStorage = false
            this.$emit('refresh')
            this.closeDialog()
        },
        async cancelStorage() {
            const afsid = this.selectedStorage.afsid
            const url = `lfs/afs/${afsid}/cancel`
            try {
                await this.$axios.post(url)
                this.fetchPurchasedStorages()
            } catch (error) {
                console.error(error)
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Could not cancel storage.',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.closeDialog()
        },
        selectStorage(storage) {
            this.selectedStorage = storage
            this.selectedTier = storage.subresource
            this.storageName = storage.long_id
            this.storageSize = storage.quota_gib
            this.addStorageDialog = true
        },
        closeDialog() {
            this.addStorageDialog = false
            this.storageName = ``
            this.storageSize = 0
            this.selectedStorage = null
            this.spaceDataError = false
        },
        async fetchSpaceCount() {
            try {
                const { data } = await this.$axios.get(`resource/${this.rpid}/lfs_mapped_space_count`)
                this.mappedSpaces = data.space_count
            } catch (error) {
                console.error(error)
            }
        },
        async fetchMounts(afsid) {
            this.fetchingMountData[afsid] = true
            try {
                const { data } = await this.$axios.get(`lfs/afs/${afsid}/mounts`)
                this.mountData = data
                this.mountDataDialog = true
            } catch (error) {
                console.error(error)
            }
            this.fetchingMountData[afsid] = false
        },
        showCancelDialog() {
            this.confirmAction = 'cancel'
            this.$store.dispatch('showGlobalDialog', {
                dialogText: `If you cancel this storage plan, it will still be available until <strong>${this.timestampToDate(
                    this.selectedStorage.next_billing_date
                )}</strong>, afterwards the stored data will be irrecoverably deleted.<br/><br/>Please refer to our documentation <a href="https://docs.nuvolos.cloud/features/file-system-and-storage/large-file-storage">here</a>.`,
                dialogAction: ['confirm', 'back'],
                dialogTitle: 'Cancel storage plan'
            })
        },
        showConfirmAddDialog() {
            this.$store.dispatch('showGlobalDialog', {
                dialogText: `By adding this storage, you will incur <strong>AC ${this.totalPrice}</strong> cost to your resource pool.`,
                dialogAction: ['confirm', 'cancel'],
                dialogTitle: 'Adding new storage'
            })
        },
        showConfirmEditDialog() {
            if (this.storageSize === this.selectedStorage.quota_gib) {
                this.editStorage()
                return
            }
            this.confirmAction = 'modify'
            this.$store.dispatch('showGlobalDialog', {
                dialogText: `Please note that by modifying the size of the storage plan period will be restarted with a start date of <strong>${this.today}</strong>.`,
                dialogAction: ['confirm', 'cancel'],
                dialogTitle: 'Modifying storage'
            })
        },
        insufficientFunds() {
            this.$store.dispatch('showGlobalDialog', {
                dialogText: `This resource pool does not have the <strong>required amount of credits</strong>.<br /><br/>Please add the necessary credits before creating or extending this storage.`,
                dialogAction: ['close'],
                dialogTitle: 'Insufficient funds'
            })
        },
        timestampToDate(timestamp) {
            const date = DateTime.fromISO(timestamp)
            return date.toISODate()
        }
    },
    created() {
        eventBus.$on('global-dialog-accept', () => {
            if (!this.selectedStorage) {
                this.addStorage()
                return
            }
            if (this.confirmAction === 'cancel') {
                this.cancelStorage()
                this.confirmAction = null
                return
            }
            if (this.confirmAction === 'modify') {
                this.editStorage()
                this.confirmAction = null
            }
        })
    },
    destroyed() {
        eventBus.$off('global-dialog-accept')
    },
    watch: {
        selectedTierObject(to) {
            this.storageSize = this.selectedStorage.quota_gib || to.min_size_gib
        },
        rpid: {
            handler(to) {
                if (!to) return
                this.hasError = false
                this.spaceDataError = false
                this.fetchPurchasedStorages()
                this.fetchAvailableStorages()
                this.fetchSpaceCount()
            },
            immediate: true
        }
    }
}
</script>

<template>
    <v-row>
        <v-col md="6" lg="4" xl="3" cols="12" v-for="storage in items" :key="storage.afsid">
            <v-card flat color="grey lighten-3">
                <v-card-title>
                    <v-list two-line color="transparent" class="pa-0" width="100%">
                        <v-list-item class="pa-0">
                            <v-list-item-avatar class="my-0">
                                <v-icon v-if="!storage.cancellation_timestamp" class="primary lighten-1" dark>check_circle</v-icon>
                                <v-icon v-else class="grey lighten-1" dark>cancel</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content class="py-0">
                                <v-list-item-title>{{ storage.long_id }}</v-list-item-title>
                                <v-list-item-subtitle v-if="!storage.cancellation_timestamp">
                                    Next billing date: {{ storage.next_billing_date | dateToHuman }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-else>Available until: {{ storage.last_availability_date | dateToHuman }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-title>
                <v-card-text>
                    <v-row class="text-center">
                        <v-col cols="12" sm="6">
                            <div class="text-h5 secondary--text">{{ storage.quota_gib }}</div>
                            <label class="text--secondary">Quota (GiB)</label>
                        </v-col>
                        <v-divider vertical></v-divider>
                        <v-col cols="12" sm="6">
                            <div class="text-h5 secondary--text">
                                {{ storage.mounted_count || 0 }}
                            </div>
                            <label class="text--secondary">
                                <a @click="$emit('showMountDialog', storage.afsid)" v-if="selectedRP?.is_rp_manager">
                                    Spaces using
                                    <v-icon x-small color="primary">mdi-arrow-top-right-thick</v-icon>
                                </a>
                                <template v-else>Spaces using</template>
                            </label>
                        </v-col>
                        <v-col cols="12" class="px-14 pt-4">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-progress-linear
                                        v-on="on"
                                        :value="calculateUsage(storage)"
                                        rounded
                                        :color="usageColor(storage)"
                                        class="my-2"
                                        height="8"></v-progress-linear>
                                </template>
                                <span>{{ humanFileSize(storage.storage_used) }}</span>
                            </v-tooltip>
                            <label class="text--secondary">
                                <strong>{{ calculateUsage(storage) }}% used</strong>
                            </label>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="align-start" v-if="selectedRP?.is_rp_manager">
                    <v-spacer />
                    <v-btn text color="primary" @click="$emit('storageSelected', storage)" v-if="!storage.cancellation_timestamp" :disabled="!canModify">
                        Modify
                    </v-btn>
                    <v-btn v-else text disabled>Cancelled</v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import { humanFileSize } from '@/utils.js'

export default {
    props: {
        items: Array,
        canModify: {
            type: Boolean,
            default: true
        },
        selectedRP: Object
    },
    methods: {
        calculateUsage(storage) {
            const oneGiB = 2 ** 30
            const totalStorage = oneGiB * storage.quota_gib
            return Math.floor((storage.storage_used / totalStorage) * 100)
        },
        usageColor(storage) {
            const colors = ['primary', 'primary', 'warning', 'error']
            const percentageToIndex = Math.floor(this.calculateUsage(storage) / 4)
            return colors[percentageToIndex]
        },
        humanFileSize
    }
}
</script>
